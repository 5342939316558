import * as React from "react"

const Footer = () => (
  <footer className="page-footer">
    <div className="container">
      <div className="row">
        <div className="col">
          <p className="text-center p-small">
            © 2021 Advanced Security Training
          </p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
