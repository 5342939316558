import * as React from "react"
import Footer from "./footer"

import "../assets/styles/main.scss"

const Layout = ({ children }) => {
  return (
    <>
      <main className="page-main">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
